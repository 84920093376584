.product {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    background-color: #f0f8ff; /* Light pastel blue */
}

.product:hover {
    transform: scale(1.05);
    background-color: #e6e6fa; /* Light pastel lavender */
}

.product-image {
    width: 100%;
    height: 0;
    padding-bottom: 100%; /* Makes the image container square */
    object-fit: cover; /* Ensure the image covers the area */
    border-radius: 8px;
    background-size: cover;
    background-position: center;
}

.product-name {
    font-size: 1.2em;
    margin: 8px 0;
}

.product-size {
    color: #555;
}

.product button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #e63946; /* Consistent button color */
    color: white;
    transition: background-color 0.3s;
}

.product button:hover {
    background-color: #d62828; /* Slightly darker shade */
}