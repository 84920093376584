.footer {
    background-color: #5880d6;
    color: white;
    text-align: center;
    padding: 10px 0;
    position: relative;
    width: 100%;
    bottom: 0;
}

.footer .container {
    max-width: 1200px;
    margin: 0 auto;
}