.cart-page {
    padding: 16px;
    background-color: #C8CFA0; /* Light pastel green */
}

.cart-page h2 {
    font-size: 2em;
    margin-bottom: 16px;
}

.cart-page ul {
    list-style: none;
    padding: 0;
}

.cart-page li {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cart-page img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 16px;
}

.cart-page button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #e63946; /* Consistent button color */
    color: white;
    transition: background-color 0.3s;
}

.cart-page button:hover {
    background-color: #d62828; /* Slightly darker shade */
}

.cart-summary {
    margin-top: 32px;
    padding: 16px;
    background-color: #faf0e6; /* Light pastel peach */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.cart-summary h3 {
    font-size: 1.5em;
    margin-bottom: 16px;
}

.cart-summary p {
    font-size: 1.2em;
    margin: 8px 0;
}

.cart-summary button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #e63946; /* Consistent button color */
    color: white;
    transition: background-color 0.3s;
}

.cart-summary button:hover {
    background-color: #d62828; /* Slightly darker shade */
}