.hero-banner {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    overflow: hidden;
}

.hero-banner img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.hero-banner img.active {
    opacity: 1;
}

.hero-content {
    position: relative;
    z-index: 1;
    max-width: 600px;
}

.hero-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
}

.hero-button:hover {
    background-color: #0056b3;
}
